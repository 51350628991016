import React from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Col,
  Tab,
  Modal,
  Row,
  Accordion,
} from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { revisiPengajuan } from "stores/Pengajuan/function";
import Moment from "moment";
import { getAllPengajuan } from "stores/Pengajuan/function";
import { number } from "prop-types";
import { updateStatusManual } from "stores/Pengajuan/function";
import CurrencyInput from "react-currency-input-field";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { getPengajuanByIdPengajuan } from "stores/Pengajuan/function";
import { pengajuanUlangDokumen } from "stores/Pengajuan/function";
import { pengajuanBPD } from "stores/PengajuanKoor/function";
import { revisiPengajuanDariKoor } from "stores/PengajuanKoor/function";
import { getAllCabangKoor } from "stores";
import { Input, Label } from "reactstrap";

function indexSteps() {
  const dispatch = useDispatch();
  const pengajuan = useSelector((state) => state.pengajuanKoorReducer);
  // console.log(pengajuan, "LLLLL");
  const valDetailPengajuanById = pengajuan.listPengajuanById;
  // console.log(valDetailPengajuanById, "MMMM");
  const auth = useSelector((state) => state.authKoorReducer);
  const role = localStorage.getItem("Role");

  // console.log(pengajuan, "DARI STORE");
  const [listUrlDokumen, setListUrlDokumen] = React.useState([]);
  const valDetailStepEnam = !pengajuan.detailPengajuan.stepEnam
    ? []
    : pengajuan.detailPengajuan.stepEnam;
  const valDetail = pengajuan.detailPengajuan;
  // console.log(valDetail, "PPP");
  const urlPreview = pengajuan.urlPreview;
  const judulPdf = pengajuan.titlePreview;
  const [modalRevisi, setModalRevisi] = React.useState(false);
  const [keterangan, setKeterangan] = React.useState("");
  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [modalUpdateStatus, setModalUpdateStatus] = React.useState(false);
  const [keteranganUpdate, setKeteranganUpdate] = React.useState("");
  const [nominalPencairan, setNominalPencairan] = React.useState("");
  const [noAkad, setNoAkad] = React.useState("");
  const [tanggalAkad, setTanggalAkad] = React.useState("");
  const [noAgenda, setNoAgenda] = React.useState("");
  const [tanggalAgenda, setTanggalAgenda] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [modalAjukanKoor, setModalAjukanKoor] = React.useState(false);
  const storeCabang = useSelector((state) => state.cabangReducer);
  const [text, setText] = React.useState("");
  const [listCabang, setListCabang] = React.useState([]);
  const [showEditCabang, setShowEditCabang] = React.useState(false);
  const [pilihCabang, setPilihCabang] = React.useState({
    label: `${valDetail.lokasiPencairan?.namaCabang} - ${valDetail.lokasiPencairan.singkatan}`,
    value: valDetail.stepEmpat.idCabangPencairan || "",
    singkatan: valDetail.lokasiPencairan?.singkatan || "",
  });
  const [idCabang, setIdCabang] = React.useState(
    valDetail.lokasiPencairan?.idCabangPencairan
  );
  const [singkatan, setSingkatan] = React.useState(
    valDetail.lokasiPencairan?.singkatan
  );

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const submitPilihCabang = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      editCabangPencairan({
        idCabang: idCabang,
        singkatan: singkatan,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setIdCabang(null);
          setPilihCabang(null);
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  React.useEffect(() => {
    let tmp = [];
    Object.entries(valDetailStepEnam).forEach(([key, value]) => {
      if (
        key != "_id" &&
        key != "id_pinjaman" &&
        key != "createdAt" &&
        key != "updatedAt"
      ) {
        tmp.push({
          key: key,
          url: value,
        });
      }
    });
    setListUrlDokumen(tmp);
  }, []);

  // const ajukanPengajuan = () => {
  //   Swal.fire({
  //     title: "Konfirmasi Pengajuan",
  //     text: "Pastikan data yang anda ajukan benar !!!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Ya, Ajukan",
  //     cancelButtonText: "Batal",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       pengajuanBPD({
  //         idPengajuan: valDetail.id_pinjaman,
  //       })
  //         .then((response) => {
  //           if (response.data.status === 200) {
  //             dispatch({ type: "SET_STEP_PENGAJUAN", data: true });
  //             Swal.fire({
  //               title: "Berhasil",
  //               text: response.data.message,
  //               icon: "success",
  //             });
  //           } else {
  //             Swal.fire({
  //               title: "Error",
  //               text: response.data.message,
  //               icon: "error",
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("ERRORRRRR", err);
  //           Swal.fire({
  //             title: "Error",
  //             text: "Terjadi kesalahan saat mengajukan pengajuan.",
  //             icon: "error",
  //           });
  //         });
  //     }
  //   });
  // };

  const ajukanPengajuan = () => {
    Swal.fire({
      title: "Konfirmasi Pengajuan",
      text: "Pastikan data yang anda ajukan benar !!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Ajukan",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          if (!text || text.trim() === "") {
            Swal.fire({
              title: "Error",
              text: "ID Pengajuan BPD tidak boleh kosong.",
              icon: "error",
            });
            resolve();
          } else {
            pengajuanBPD({
              idPengajuan: valDetail.id_pinjaman,
              idPengajuanBpd: text,
              idCabangPencairan: pilihCabang.value,
            })
              .then((response) => {
                if (response.data.status === 200) {
                  dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                  Swal.fire({
                    title: "Berhasil",
                    text: response.data.message,
                    icon: "success",
                  });
                  setModalAjukanKoor(false);
                  getPengajuanByIdPengajuan(dispatch, {
                    idPengajuan: valDetail.id_pinjaman,
                  }).then((response) => {
                    if (response.status === 200) {
                      dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                      dispatch({
                        type: "SET_DETAIL_PENGAJUAN",
                        data: response.data.data[0],
                      });
                    }
                  });
                } else {
                  Swal.fire({
                    title: "Gagal Mengajukan",
                    text: response.data.message,
                    icon: "error",
                  });
                  getPengajuanByIdPengajuan(dispatch, {
                    idPengajuan: valDetail.id_pinjaman,
                  }).then((response) => {
                    if (response.status === 200) {
                      dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                      dispatch({
                        type: "SET_DETAIL_PENGAJUAN",
                        data: response.data.data[0],
                      });
                    }
                  });
                }
                resolve();
              })
              .catch((err) => {
                console.log("ERRORRRRR", err);
                Swal.fire({
                  title: "Error",
                  text: "Terjadi kesalahan saat mengajukan pengajuan.",
                  icon: "error",
                });
                resolve();
              });
          }
        });
      },
    });
  };

  // const ajukanPengajuan = () => {
  //   Swal.fire({
  //     title: "Konfirmasi Pengajuan",
  //     text: "Pastikan data yang anda ajukan benar !!!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Ya, Ajukan",
  //     cancelButtonText: "Batal",
  //     allowOutsideClick: () => !Swal.isLoading(),
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       return new Promise((resolve) => {
  //         pengajuanBPD({
  //           idPengajuan: valDetail.id_pinjaman,
  //           idPengajuanBpd: text,
  //           idCabangPencairan: pilihCabang.value,
  //         })
  //           .then((response) => {
  //             if (response.data.status === 200) {
  //               dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
  //               Swal.fire({
  //                 title: "Berhasil",
  //                 text: response.data.message,
  //                 icon: "success",
  //               });
  //               setModalAjukanKoor(false);
  //               getPengajuanByIdPengajuan(dispatch, {
  //                 idPengajuan: valDetail.id_pinjaman,
  //               }).then((response) => {
  //                 if (response.status === 200) {
  //                   dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
  //                   dispatch({
  //                     type: "SET_DETAIL_PENGAJUAN",
  //                     data: response.data.data[0],
  //                   });
  //                 }
  //               });
  //             } else {
  //               Swal.fire({
  //                 title: "Error",
  //                 text: response.data.message,
  //                 icon: "error",
  //               });
  //               getPengajuanByIdPengajuan(dispatch, {
  //                 idPengajuan: valDetail.id_pinjaman,
  //               }).then((response) => {
  //                 if (response.status === 200) {
  //                   dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
  //                   dispatch({
  //                     type: "SET_DETAIL_PENGAJUAN",
  //                     data: response.data.data[0],
  //                   });
  //                 }
  //               });
  //             }
  //             resolve();
  //           })
  //           .catch((err) => {
  //             console.log("ERRORRRRR", err);
  //             Swal.fire({
  //               title: "Error",
  //               text: "Terjadi kesalahan saat mengajukan pengajuan.",
  //               icon: "error",
  //             });
  //             resolve();
  //           });
  //       });
  //     },
  //   });
  // };

  const ajukanUlangDokumen = () => {
    Swal.fire({
      title: "Konfirmasi Pengajuan",
      text: "Pastikan data yang anda ajukan benar !!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Ajukan",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          pengajuanUlangDokumen({
            idPengajuan: valDetail.id_pinjaman,
          })
            .then((response) => {
              if (response.data.status === 200) {
                dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                Swal.fire({
                  title: "Berhasil",
                  text: response.data.message,
                  icon: "success",
                });
                getPengajuanByIdPengajuan(dispatch, {
                  idPengajuan: valDetail.id_pinjaman,
                }).then((response) => {
                  if (response.status === 200) {
                    dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                    dispatch({
                      type: "SET_DETAIL_PENGAJUAN",
                      data: response.data.data[0],
                    });
                  }
                });
              } else {
                dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                });
                getPengajuanByIdPengajuan(dispatch, {
                  idPengajuan: valDetail.id_pinjaman,
                }).then((response) => {
                  if (response.status === 200) {
                    dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
                    dispatch({
                      type: "SET_DETAIL_PENGAJUAN",
                      data: response.data.data[0],
                    });
                  }
                });
              }
              resolve();
            })
            .catch((err) => {
              console.log("ERRORRRRR", err);
              Swal.fire({
                title: "Error",
                text: "Terjadi kesalahan saat mengajukan pengajuan.",
                icon: "error",
              });
              resolve();
            });
        });
      },
    });
  };

  const handleClick = (idPengajuanValue) => {
    setIdPengajuan(idPengajuanValue);
    dispatch({ type: "SET_STEP_PENGAJUAN", data: true });
    dispatch({ type: "SET_DETAIL_PENGAJUAN_LAMA", data: true });
  };

  const submitRevisi = () => {
    if (keterangan === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Keterangan tidak boleh kosong",
      });
    } else {
      revisiPengajuanDariKoor({
        bapLoan: idPengajuan,
        keterangan: keterangan,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
          }).then(() => {
            dispatch({ type: "SET_STEP_PENGAJUAN", data: true });
          });
          setTimeout(() => {
            Swal.close();
          }, 5000);
          setModalRevisi(false);
          getAllPengajuan(dispatch, { status: "" });
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const submitUpdateStatus = (e) => {
    e.preventDefault();
    if (status === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Status tidak boleh kosong",
      });
    } else {
      if (status.value === "Disetujui") {
        if (
          nominalPencairan === 0 ||
          noAkad === "" ||
          tanggalAkad === "" ||
          noAgenda === "" ||
          tanggalAgenda === ""
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Wajib di isi semua!",
          });
        } else {
          updateStatusManual({
            idPinjaman: idPengajuan,
            statusPengajuan: status.value,
            nominalPencairan: nominalPencairan,
            noAkad: noAkad,
            noAgenda: noAgenda,
            tglAkad: tanggalAkad,
            tglAgenda: tanggalAgenda,
          }).then((response) => {
            // console.log(response, "TESSSSSSSSSSSSSSS");
            if (response.data.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
              }).then((response) => {
                setModalUpdateStatus(false);
                setStatus("");
                setNoAgenda("");
                setNoAkad("");
                setNominalPencairan("");
                setTanggalAgenda("");
                setTanggalAkad("");
                getAllPengajuan(dispatch, { status: "" });
                dispatch({ type: "SET_STEP_PENGAJUAN", data: true });
              });
              setTimeout(() => {
                Swal.close();
              }, 1000);
            } else if (response.data.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          });
        }
      } else {
        if (keteranganUpdate === "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Keterangan tidak boleh kosong",
          });
        } else {
          updateStatusManual({
            idPinjaman: idPengajuan,
            statusPengajuan: status.value,
            keterangan: keteranganUpdate,
            nominalPencairan: nominalPencairan,
            noAkad: noAkad,
            tglAkad: tanggalAkad,
            noAgenda: noAgenda,
            tglAgenda: tanggalAgenda,
          }).then((response) => {
            if (response.data.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
              }).then(() => {
                setModalUpdateStatus(false);
                setKeterangan("");
                setStatus("");
                setNoAgenda("");
                setNoAkad("");
                setNominalPencairan("");
                setTanggalAgenda("");
                setTanggalAkad("");
                getAllPengajuan(dispatch, { status: "" });
                dispatch({ type: "SET_STEP_PENGAJUAN", data: true });
              });
              setTimeout(() => {
                Swal.close();
              }, 1000);
            } else if (response.data.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          });
        }
      }
    }
  };

  const handleModalClose = () => {
    setText("");
    setPilihCabang({
      label: `${valDetail.lokasiPencairan?.namaCabang} - ${valDetail.lokasiPencairan.singkatan}`,
      value: valDetail.stepEmpat.idCabangPencairan,
      singkatan: valDetail.lokasiPencairan?.singkatan,
    });
    setShowEditCabang(false);
  };

  const handleCloseModalUpdatStatus = () => {
    setStatus("");
    setKeterangan("");
    setNominalPencairan("");
    setNoAkad("");
    setTanggalAkad("");
    setNoAgenda("");
    setTanggalAgenda("");
  };

  const statusStyle = (status) => {
    switch (status) {
      case "Menunggu Konfirmasi":
        return { backgroundColor: "#FFF5CD", textColor: "#FFAF05" };
      case "Diajukan":
        return { backgroundColor: "#BAD9FD", textColor: "#1776F1" };
      case "Diajukan (File Gagal Terkirim)":
        return { backgroundColor: "#ECE6FA", textColor: "#5729CE" };
      case "Diproses":
        return { backgroundColor: "#fcceb3", textColor: "#fa5d02" };
      case "Diproses (File Gagal Terkirim)":
        return { backgroundColor: "#ECC7BE", textColor: "#9F2305" };
      case "Disetujui":
        return { backgroundColor: "#E3FCDB", textColor: "#5AC967" };
      case "Revisi":
        return { backgroundColor: "#FFE2E6", textColor: "#FF2B4B" };
      case "Ditolak":
        return { backgroundColor: "#E10F00", textColor: "#ffffff" };
      default:
        return {};
    }
  };

  const options = [
    {
      value: "",
      label: "Pilih Status",
      isDisabled: true,
    },
    // {
    //   value: "Menunggu Konfirmasi",
    //   label: "Menunggu Konfirmasi",
    // },
    {
      value: "Disetujui",
      label: "Disetujui",
    },
    {
      value: "Ditolak",
      label: "Ditolak",
    },
  ];

  // const submitRevisi = () => {
  //   if (keterangan === "") {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Keterangan tidak boleh kosong",
  //     });
  //   } else {
  //     revisiPengajuan({
  //       idPengajuan: idPengajuan,
  //       keterangan: keterangan,
  //     }).then((response) => {
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: response.data.message,
  //         });
  //         setTimeout(() => {
  //           Swal.close();
  //         }, 5000);
  //         setModalRevisi(false);
  //       } else if (response.data.status === 400) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: response.data.message,
  //         });
  //       }
  //     });
  //   }
  // };

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeCabang.listCabangKoor &&
      storeCabang.listCabangKoor.map((val) => {
        // console.log(val, "AAA");
        tmp.push({
          value: val._id,
          label: `${val.namaCabang} - ${val.singkatan}`,
          singkatan: val.singkatan,
        });
      });

    setListCabang(tmp);
  }, [storeCabang.listCabangKoor]);

  React.useEffect(() => {
    getAllCabangKoor(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalAjukanKoor}
        // onHide={() => setModalAjukanKoor(!modalAjukanKoor)}
        onHide={() => {
          handleModalClose();
          setModalAjukanKoor(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Ajukan Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <div className="col-6"> */}
              <Label
                style={{
                  fontSize: 16,
                  marginTop: 15,
                  fontWeight: "bold",
                  color: "#828286",
                  textTransform: "uppercase",
                }}
              >
                Nomor Pengajuan
              </Label>
              <Input
                type="text"
                placeholder="Input ID Pengajuan"
                value={text}
                onChange={handleChange}
                style={{
                  fontSize: 14,
                  marginBottom: 15,
                }}
              />
              <Label
                style={{
                  fontSize: 16,
                  marginTop: 15,
                  fontWeight: "bold",
                  color: "#828286",
                  textTransform: "uppercase",
                }}
              >
                cabang pencairan
              </Label>
              <li>
                <Label
                  style={{
                    fontSize: 14,
                    marginBottom: 15,
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  {/* {valDetail.stepEmpat.idCabangPencairan ===
                    valDetail.lokasiPencairan.namaCabang
                      ? valDetail.lokasiPencairan.namaCabang
                      : valDetail.lokasiPencairan.namaCabang} */}
                  {valDetail.lokasiPencairan.namaCabang} -{" "}
                  {valDetail.lokasiPencairan.singkatan}
                </Label>
                <Button
                  variant="link"
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: showEditCabang ? "red" : "#007FFF",
                    cursor: "default",
                  }}
                  // onClick={() => setShowEditCabang(!showEditCabang)}
                >
                  {/* {showEditCabang ? "Hide" : "Ganti"} */}
                </Button>
              </li>
              {showEditCabang && (
                <>
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Edit Cabang Pencairan
                  </Label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang Pencairan"
                    value={pilihCabang}
                    onChange={(value) => {
                      console.log(value, "TESS");
                      setPilihCabang(value);
                      setIdCabang(value.value);
                      setSingkatan(value.singkatan);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                  {/* <Button
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      border: 0,
                      color: "#3F9B0B",
                      backgroundColor: "#E7F5DE",
                    }}
                    onClick={submitPilihCabang}
                  >
                    Submit Edit Cabang
                  </Button> */}
                </>
              )}
            </Card.Body>
          </Card>
          <Button
            style={{
              marginBottom: 20,
              fontWeight: "bold",
              border: 0,
              color: "#007FFF",
              backgroundColor: "#E6F2FF",
            }}
            onClick={ajukanPengajuan}
          >
            Ajukan Pengajuan
          </Button>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalUpdateStatus}
        onHide={() => {
          setModalUpdateStatus(false);
          handleCloseModalUpdatStatus();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Update Status
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatus(value)}
                            options={options}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                      {status.value === "Menunggu Konfirmasi" ||
                      status.value === "Diproses" ||
                      status.value === "Revisi" ||
                      status.value === "Ditolak" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Keterangan
                            </label>
                            <Form.Control
                              as="textarea"
                              onChange={(e) => {
                                setKeteranganUpdate(e.target.value);
                              }}
                              rows={4}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {status.value === "Disetujui" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Nominal Pencairan
                            </label>
                            <p>
                              <CurrencyInput
                                style={{
                                  padding: 5,
                                  borderRadius: 4,
                                  width: "100%",
                                  height: "40px",
                                  borderWidth: "1px",
                                  borderTopColor: "rgba(227, 227, 227, 0.3)",
                                  borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                  borderBottomColor: "rgba(227, 227, 227, 1)",
                                  borderRightColor: "rgba(227, 227, 227, 1)",
                                }}
                                name="nominal"
                                placeholder="Masukkan nominal"
                                prefix="Rp "
                                onValueChange={(value) => {
                                  setNominalPencairan(value);
                                }}
                              />
                            </p>
                          </Form.Group>
                        </Col>
                      ) : null}
                      {status.value === "Disetujui" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              No Akad
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setNoAkad(e.target.value);
                              }}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {status.value === "Disetujui" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Tanggal Akad
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setTanggalAkad(e.target.value);
                              }}
                              type="date"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {status.value === "Disetujui" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              No Agenda
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setNoAgenda(e.target.value);
                              }}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                      {status.value === "Disetujui" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Tanggal Agenda
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setTanggalAgenda(e.target.value);
                              }}
                              type="date"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitUpdateStatus}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modalRevisi}
        onHide={() => {
          setModalRevisi(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md={"12"} style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                Note Revisi
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Col sm="12">
                <Form.Control
                  as="textarea"
                  rows={4}
                  onChange={(e) => {
                    setKeterangan(e.target.value);
                  }}
                ></Form.Control>
                <Form.Text
                  className="text-muted"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Note Revisi Apa Saja Yang Diperlukan
                </Form.Text>
              </Col>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitRevisi}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
      <Row>
        <Col md={pengajuan.valueCol} style={{ marginTop: 20 }}>
          <Card>
            <Card style={{ border: 0 }}>
              <Card.Header style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className="btn-transparent mr-2"
                  type="button"
                  variant="primary"
                  size="md"
                  style={{
                    border: "none",
                    backgroundColor: "#00BFFF",
                    border: 0,
                  }}
                  onClick={handleClick}
                >
                  <i className="fas fa-arrow-left"></i>
                </Button>
                {pengajuan.pengajuanLama === true ? (
                  <>
                    <Card.Title
                      as="h3"
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        flex: 1,
                        marginBottom: 15,
                      }}
                    >
                      Detail Pengajuan
                    </Card.Title>
                  </>
                ) : (
                  <Card.Title
                    as="h3"
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      flex: 1,
                      marginBottom: 15,
                    }}
                  >
                    Detail Pengajuan Lama
                  </Card.Title>
                )}
              </Card.Header>
            </Card>
            <Card style={{ border: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 20,
                }}
              >
                {/* {auth.role === "super admin" || auth.role === "admin" ? (
                  <Button
                    disabled={
                      valDetail.statusPengajuan === "Diajukan" ||
                      valDetail.statusPengajuan === "Ditolak" ||
                      valDetail.statusPengajuan === "Disetujui" ||
                      valDetail.statusPengajuan === "Diproses" ||
                      valDetail.statusPengajuan ===
                        "Diajukan (File Gagal Terkirim)"
                        ? true
                        : false
                    }
                    style={{
                      cursor:
                        valDetail.statusPengajuan === "Diajukan" ||
                        valDetail.statusPengajuan === "Ditolak" ||
                        valDetail.statusPengajuan === "Disetujui" ||
                        valDetail.statusPengajuan === "Diproses" ||
                        valDetail.statusPengajuan ===
                          "Diajukan (File Gagal Terkirim)"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor: "#648093",
                      borderWidth: 0,
                      fontWeight: "bold",
                    }}
                    className="btn-wd mr-3"
                    size="md"
                    onClick={() => {
                      setIdPengajuan(valDetail.id_pinjaman);
                      setModalUpdateStatus(true);
                    }}
                  >
                    Update Status Manual
                  </Button>
                ) : null} */}
                {pengajuan.pengajuanLama === true ? (
                  <>
                    {auth.role === "super admin" ||
                    role === "BPD" ||
                    role === "Internal" ? (
                      <>
                        <Button
                          disabled={
                            valDetail.statusPengajuan === "Revisi"
                              ? true
                              : false ||
                                valDetail.statusPengajuan === "Diproses"
                              ? true
                              : false ||
                                valDetail.statusPengajuan ===
                                  "Diajukan (File Gagal Terkirim)"
                              ? true
                              : false ||
                                valDetail.statusPengajuan ===
                                  "Diproses (File Gagal Terkirim)"
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              valDetail.statusPengajuan === "Revisi"
                                ? "not-allowed"
                                : "pointer" &&
                                  valDetail.statusPengajuan === "Diproses"
                                ? "not-allowed"
                                : "pointer" &&
                                  valDetail.statusPengajuan ===
                                    "Diajukan (File Gagal Terkirim)"
                                ? "not-allowed"
                                : "pointer" &&
                                  valDetail.statusPengajuan ===
                                    "Diproses (File Gagal Terkirim)"
                                ? "not-allowed"
                                : "pointer",
                            backgroundColor: "#F63F4B",
                            borderWidth: 0,
                            fontWeight: "bold",
                          }}
                          className="btn-wd mr-3"
                          variant="danger"
                          size="md"
                          onClick={() => {
                            setIdPengajuan(valDetail.id_pinjaman);
                            setModalRevisi(true);
                          }}
                        >
                          Revisi
                        </Button>
                        {valDetailPengajuanById.statusKirimData === 3 ? (
                          <>
                            <Button
                              // disabled={
                              //   valDetail.statusPengajuan != "Diajukan"
                              //     ? true
                              //     : false
                              // }
                              style={{
                                // cursor:
                                //   valDetail.statusPengajuan != "Diajukan"
                                //     ? "not-allowed"
                                //     : "pointer",
                                // backgroundColor:
                                //   valDetail.statusPengajuan != "Diajukan"
                                //     ? "grey"
                                //     : "primary",
                                backgroundColor: "#5729CE",
                                border: 0,
                                fontWeight: "bold",
                              }}
                              className="btn-wd "
                              // variant="primary"
                              size="md"
                              onClick={ajukanUlangDokumen}
                            >
                              Ajukan Ulang Dokumen
                              <i
                                class="fas fa-arrow-right"
                                style={{ marginLeft: 10 }}
                              ></i>
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled={
                                valDetail.statusPengajuan != "Diajukan"
                                  ? true
                                  : false
                              }
                              style={{
                                cursor:
                                  valDetail.statusPengajuan != "Diajukan"
                                    ? "not-allowed"
                                    : "pointer",
                                backgroundColor:
                                  valDetail.statusPengajuan != "Diajukan"
                                    ? "grey"
                                    : "primary",
                                // borderWidth: 0,
                                backgroundColor: "#02CCFE",
                                border: 0,
                                fontWeight: "bold",
                              }}
                              className="btn-wd "
                              // variant="primary"
                              size="md"
                              // onClick={ajukanPengajuan}
                              onClick={() => setModalAjukanKoor(true)}
                            >
                              Ajukan
                              <i
                                class="fas fa-arrow-right"
                                style={{ marginLeft: 10 }}
                              ></i>
                            </Button>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Card>

            <div style={{ marginLeft: 15, marginRight: 15 }}>
              <div className="row">
                <div className="col-md-12">
                  <Card
                    style={{
                      backgroundColor: statusStyle(valDetail.statusPengajuan)
                        .backgroundColor,
                      borderRadius: 10,
                      // padding: "5px 10px 20px 15px",
                      // position: "relative",
                      // display: "flex",
                    }}
                  >
                    <i
                      class="fas fa-info-circle"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        fontSize: "25px",
                        transform: "translateY(-50%)",
                        color: statusStyle(valDetail.statusPengajuan).textColor,
                      }}
                    ></i>
                    <label
                      style={{
                        color: statusStyle(valDetail.statusPengajuan).textColor,
                        marginLeft: 45,
                        fontWeight: "bold",
                        fontSize: 16,
                        marginTop: 20,
                      }}
                    >
                      {valDetail.statusPengajuan}
                    </label>
                    {valDetail.statusPengajuan === "Menunggu Konfirmasi" && (
                      <p style={{ marginLeft: 45 }}>
                        Pengajuan Anda sedang menunggu untuk tahap pengecekan.
                      </p>
                    )}
                    {valDetail.statusPengajuan === "Diproses" && (
                      <p style={{ marginLeft: 45 }}>
                        Pengajuan sedang dalam proses oleh pihak Bank KALSEL.
                      </p>
                    )}
                    {valDetail.statusPengajuan === "Revisi" && (
                      <p
                        style={{ marginLeft: 45, textTransform: "capitalize" }}
                      >
                        {valDetail.note_reject ? valDetail.note_reject : "-"}
                      </p>
                    )}
                    {valDetail.statusPengajuan === "Disetujui" && (
                      <p style={{ marginLeft: 45 }}>
                        Pengajuan pinjaman telah disetujui oleh pihak Bank
                        KALSEL
                      </p>
                    )}
                    {valDetail.statusPengajuan === "Ditolak" && (
                      <p style={{ marginLeft: 45, color: "white" }}>
                        Pengajuan pinjaman telah ditolak oleh pihak Bank KALSEL
                      </p>
                    )}
                    {valDetail.statusPengajuan === "Diajukan" && (
                      <p style={{ marginLeft: 45 }}>
                        Pengajuan telah diajukan ke pihak Bank KALSEL
                      </p>
                    )}
                    {valDetail.statusPengajuan ===
                      "Diajukan (File Gagal Terkirim)" && (
                      <p style={{ marginLeft: 45 }}>
                        Data pengajuan berhasil diajukan tetapi file gagal
                        terkirim, coba ajukan ulang..
                      </p>
                    )}
                    {valDetail.statusPengajuan ===
                      "Diproses (File Gagal Terkirim)" && (
                      <p style={{ marginLeft: 45 }}>
                        Data pengajuan berhasil diproses tetapi file gagal
                        terkirim.
                      </p>
                    )}
                  </Card>
                </div>
              </div>
            </div>

            <Card.Body>
              <Tab.Container id="plain-tabs-example" defaultActiveKey="step1">
                <Nav role="tablist" variant="tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="step1"
                      style={{ color: "#65869F", fontWeight: "bold" }}
                    >
                      Informasi Nasabah
                    </Nav.Link>
                  </Nav.Item>
                  {valDetail.statusMenikah === "KAWIN" ? (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="step2"
                        style={{ color: "#65869F", fontWeight: "bold" }}
                      >
                        Informasi Pasangan
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="step3"
                      style={{ color: "#65869F", fontWeight: "bold" }}
                    >
                      Informasi Pekerjaan
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="step4"
                      style={{ color: "#65869F", fontWeight: "bold" }}
                    >
                      Informasi Pinjaman
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="step5"
                      style={{ color: "#65869F", fontWeight: "bold" }}
                    >
                      Informasi Jaminan
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="step6"
                      style={{ color: "#65869F", fontWeight: "bold" }}
                    >
                      Dokumen
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="step1">
                    <Step1 />
                  </Tab.Pane>
                  <Tab.Pane eventKey="step2">
                    <Step2 />
                  </Tab.Pane>

                  <Tab.Pane eventKey="step3">
                    <Step3 />
                  </Tab.Pane>

                  <Tab.Pane eventKey="step4">
                    <Step4 />
                  </Tab.Pane>

                  <Tab.Pane eventKey="step5">
                    <Step5 />
                  </Tab.Pane>
                  <Tab.Pane eventKey="step6">
                    <Step6 />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
        {pengajuan.valueCol === "12" ? null : (
          <>
            <Col md={pengajuan.valueColPrev} style={{ marginTop: 20 }}>
              <Card>
                <Card style={{ border: 0 }}>
                  <Card.Header
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      className="btn-transparent mr-2"
                      type="button"
                      variant="danger"
                      size="md"
                      style={{ border: "none" }}
                      onClick={() => {
                        dispatch({ type: "HIDE_DOKUMEN" });
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    <Card.Title
                      as="h3"
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        flex: 1,
                        marginBottom: 15,
                      }}
                    >
                      Preview Dokumen
                    </Card.Title>
                  </Card.Header>
                </Card>

                <Card.Body>
                  {/* {listUrlDokumen.map((val) => {
                    return (
                      <>
                        <label>{val.key}</label>
                      </>
                    );
                  })} */}
                  {/* <label> {judulPdf} </label> */}
                  <iframe src={urlPreview} width="100%" height="700px" />
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Row>
      <Card>
        <Card.Header>
          <Card.Title
            as="h3"
            style={{
              color: "black",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            history pengajuan
            <hr></hr>
          </Card.Title>
        </Card.Header>
        <div
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          <VerticalTimeline lineColor="#113946">
            {valDetail.history.map((item, index) => (
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  borderTop:
                    item.status === "Menunggu Konfirmasi"
                      ? "7px solid  rgb(255, 175, 5)"
                      : item.status === "Diajukan"
                      ? "7px solid  rgb(186, 217, 253)"
                      : item.status === "Diajukan (File Gagal Terkirim)"
                      ? "7px solid  rgb(87, 41, 206)"
                      : item.status === "Diproses"
                      ? "7px solid  rgb(250, 93, 2)"
                      : item.status === "Disetujui"
                      ? "7px solid  rgb(90, 201, 103)"
                      : item.status === "Revisi"
                      ? "7px solid  rgb(255, 43, 75)"
                      : "7px solid  rgb(255, 15, 0)",
                }}
                key={index}
                date={moment(item.date).format("DD - MMMM - YYYY HH:mm:ss")}
                iconStyle={{
                  background:
                    item.status === "Menunggu Konfirmasi"
                      ? "#FFAF05"
                      : item.status === "Diajukan"
                      ? "#BAD9FD"
                      : item.status === "Diajukan (File Gagal Terkirim)"
                      ? "#5729CE"
                      : item.status === "Diproses"
                      ? "#fa5d02"
                      : item.status === "Disetujui"
                      ? "#5AC967"
                      : item.status === "Revisi"
                      ? "#FF2B4B"
                      : "#E10F00",
                  color: "#fff",
                }}
              >
                <h3
                  className="vertical-timeline-element-title"
                  style={{ fontWeight: "bold" }}
                >
                  {item.status}
                </h3>
                <div style={{ marginTop: "15px" }}>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Keterangan
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {!item.keterangan || item.keterangan === ""
                          ? "-"
                          : item.keterangan}
                      </label>
                    </Col>
                  </Row>
                </div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </Card>
    </>
  );
}

export default indexSteps;
