import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function loginKoordinator(dispatch, data, history) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webApiBpd/loginKoordinator", data)
    .then(async (respon) => {
      // console.log(respon, "RES");
      let { fullname, email, koordinator } = respon.data.data.data;
      Swal.close();
      localStorage.setItem("token", respon.data.data.token);
      localStorage.setItem("Role", "BPD");
      dispatch({
        type: "SET_IDENTITY_KOORDINATOR",
        data: { fullname, email, koordinator },
      });
      history.push("/koor/pengajuanKoordinator");
    })
    .catch((err) => {
      console.log(err, "ERRR");
      errorHandler(err.response);
    });
}

export function refreshKoordinator(dispatch) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get("/webApiBpd/refreshLoginKoordinator", {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      let { fullname, email, koordinator } = respon.data.data.data;
      // let { nama, nopeg, role, idClient, idCabang, idUser, kodeUser } =
      //   respon.data;
      localStorage.setItem("token", respon.data.data.token);
      localStorage.setItem("Role", "BPD");

      dispatch({
        type: "SET_IDENTITY_KOORDINATOR",
        data: { fullname, email, koordinator },
      });
      Swal.close();
    })
    .catch(errorHandler);
}
