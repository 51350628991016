const initialState = {
  fullname: "",
  email: "",
  koordinator: "",
};

const authKoorReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_IDENTITY_KOORDINATOR":
      return {
        ...state,
        fullname: data.fullname,
        email: data.email,
        koordinator: data.koordinator,
      };
    default:
      return state;
  }
};

export default authKoorReducer;
