import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { number } from "prop-types";
import DetailPengajuan from "./TabPengajuan/indexSteps";
import PengajuanLama from "./TabPengajuan/pengajuanLama";
import { useDispatch, useSelector } from "react-redux";
import { getAllPengajuan } from "stores/Pengajuan/function";
import { getAllStatusPengajuan } from "stores/Pengajuan/function";
import {
  Dna,
  InfinitySpin,
  LineWave,
  Oval,
  Hourglass,
} from "react-loader-spinner";
import { cekStatusPengajuan } from "stores/Pengajuan/function";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { getPengajuanByIdPengajuan } from "stores/Pengajuan/function";
import { pencairanAll } from "stores/Pengajuan/function";
import { getAllPengajuanKoor } from "stores/PengajuanKoor/function";
import { ambilTotalPengajuan } from "stores/PengajuanKoor/function";
import { ambilDiproses } from "stores/PengajuanKoor/function";
import { ambilGagalTerkirim } from "stores/PengajuanKoor/function";
import { ambilTotalDiajukan } from "stores/PengajuanKoor/function";
import { ambilTotalDitolak } from "stores/PengajuanKoor/function";

function Pengajuan() {
  const modalDetailPengajuan = useSelector(
    (state) => state.pengajuanKoorReducer
  );
  const totalPengajuan = useSelector((state) => state.pengajuanKoorReducer);
  // console.log(totalPengajuan, "AAAA");
  const auth = useSelector((state) => state.authKoorReducer);
  const pengajuan = useSelector((state) => state.pengajuanKoorReducer);
  const dispatch = useDispatch();
  const [listPengajuan, setListPengajuan] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [listStatus, setListStatus] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [modalCekStatus, setModalCekStatus] = React.useState(false);
  const [hasilCekStatus, setHasiCekStatus] = React.useState(null);
  const [detailPengajuan, setDetaiPengajuan] = React.useState(null);
  const [pending, setPending] = React.useState(false);
  const [statusPengajuan, setStatusPengajuan] = React.useState("Diajukan");
  const [statusPengajuanDisetujui, setStatusPengajuanDisetujui] =
    React.useState("Disetujui");
  const [statusPengajuanDiproses, setStatusPengajuanDiproses] =
    React.useState("Diproses");
  const [statusPengajuanGagalTerkirim, setStatusPengajuanGagalTerkirim] =
    React.useState("Diproses (File Gagal Terkirim)");
  const [statusPengajuanDiajukan, setStatusPengajuanDiajukan] =
    React.useState("Diajukan");
  const [statusPengajuanDitolak, setStatusPengajuanDitolak] =
    React.useState("Ditolak");
  //Testing Pagination
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pageTotalPengajuan, setPageTotalPengajuan] = React.useState(0);
  const [totalPerHalamanTotalPengajuan, setTotalPerHalamanTotalPengajuan] =
    React.useState(10);

  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
    setPage(0);
  };

  const handleFilterChange = () => {
    setPending(true);

    getAllPengajuanKoor(dispatch, {
      status: statusPengajuan,
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const clickPengajuanDisetujui = (val) => {
    // console.log(val, "YYYY");
    dispatch({ type: "SET_STEP_PENGAJUAN_DISETUJUI", data: false });
  };

  const handleDetailsClick = (val) => {
    // console.log(val, "YYYY");
    dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });

    getPengajuanByIdPengajuan(dispatch, {
      idPengajuan: val.id_pinjaman,
    }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
        dispatch({ type: "SET_DETAIL_PENGAJUAN", data: response.data.data[0] });
      }
    });
  };

  const hidePengajuanLama = () => {
    dispatch({ type: "SET_PENGAJUAN_LAMA", data: false });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    if (pengajuan.listPengajuan.data) {
      let tmp = pengajuan.listPengajuan.data
        // .filter((val) => !val.jenisAjukan)
        .map((val) => {
          // console.log(val, "VALL");
          let tmpTanggalPengajuanBpd = "";
          let updateBy = "";
          for (var i = 0; i < val.history.length; i++) {
            if (val.history[i].status === "Diproses") {
              tmpTanggalPengajuanBpd = val.history[i].date;
              break;
            }
          }
          for (var i = 0; i < val.history.length; i++) {
            if (val.history[i].status === "Disetujui") {
              updateBy = val.history[i].keterangan;
              break;
            }
          }

          return {
            ...val,
            namaCabangPencairan: !val.lokasiPencairan
              ? "-"
              : val.lokasiPencairan.namaCabang,
            tanggalPengajuanBpd:
              tmpTanggalPengajuanBpd === ""
                ? "-"
                : moment(new Date(tmpTanggalPengajuanBpd)).format(
                    "DD MMMM YYYY"
                  ),
            tanggalPencairan:
              val.statusPengajuan === "Disetujui"
                ? moment(new Date(val.tglAkad)).format("DD MMMM YYYY")
                : "-",
            diupdateOleh: updateBy === "" ? "-" : updateBy,
            action: (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Details Pengajuan</Tooltip>}
                >
                  <Button
                    className="mr-0"
                    // variant="info"
                    style={{
                      marginTop: 5,
                      backgroundColor: "#FF474C",
                      border: 0,
                    }}
                    onClick={() => {
                      // console.log(val, "TTTT");
                      handleDetailsClick(val);
                    }}
                  >
                    <i class="fas fa-info"></i>
                  </Button>
                </OverlayTrigger>{" "}
                {/* <>
                <Button
                  disabled={
                    val.statusPengajuan !== "Diproses"
                    // && val.statusPengajuan !== "Diajukan"
                  }
                  className="ml-2"
                  style={{
                    cursor:
                      val.statusPengajuan !== "Diproses"
                        ? // &&val.statusPengajuan !== "Diajukan"
                          "not-allowed"
                        : "pointer",
                    backgroundColor:
                      val.statusPengajuan !== "Diproses"
                        ? // && val.statusPengajuan !== "Diajukan"
                          "grey"
                        : "#FF6A49",
                    borderWidth: 0,
                    marginTop: 5,
                    // backgroundColor: "#53ABA0",
                    border: 0,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    let siapCek = {
                      idPinjaman: val.id_pinjaman,
                      nik: val.nomorIdentitas,
                    };
                    Swal.fire({
                      title: "Loading ...",
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      onBeforeOpen: () => {
                        Swal.showLoading();
                      },
                    });

                    cekStatusPengajuan(siapCek)
                      .then((response) => {
                        Swal.close();

                        console.log(response, "OOO");
                        if (response.status === 200) {
                          Swal.fire({
                            title: "Berhasil",
                            text: response.data.message,
                            icon: "success",
                          });
                          getAllPengajuan(dispatch, {
                            status:
                              filterStatus === null || filterStatus.value === ""
                                ? ""
                                : filterStatus.value,
                            cari: "",
                            page: page + 1,
                            limit: totalPerHalaman,
                          });
                          setModalCekStatus(!modalCekStatus);
                          setDetaiPengajuan(val);
                          setHasiCekStatus(response.data);
                        } else {
                          Swal.fire({
                            title: "Gagal",
                            text: "Gagal melakukan cek status",
                            icon: "error",
                          });
                        }
                      })
                      .catch((error) => {
                        // Menutup loading Swal jika terjadi kesalahan
                        Swal.close();
                        console.error("Error:", error);
                      });
                  }}
                >
                  Cek Status
                </Button>
              </> */}
              </>
            ),
          };
        });
      // console.log(tmp, "KKK");
      setListPengajuan(tmp);
    }
  }, [pengajuan.listPengajuan.data]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    let tmp = [
      { value: "", label: "All" },
      {
        value: "Menunggu Konfirmasi",
        label: "Menunggu Konfirmasi",
      },
      {
        value: "Diajukan",
        label: "Diajukan",
      },
      {
        value: "Diajukan (File Gagal Terkirim)",
        label: "Diajukan (File Gagal Terkirim)",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },
      {
        value: "Diproses (File Gagal Terkirim)",
        label: "Diproses (File Gagal Terkirim)",
      },
      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [pengajuan.listPengajuan.data]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu Konfirmasi":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFF5CD",
          textColor: "#FFAF05",
        };
      case "Diajukan":
        return {
          fontWeight: "bold",
          backgroundColor: "#BAD9FD",
          textColor: "#1776F1",
        };
      case "Diajukan (File Gagal Terkirim)":
        return {
          fontWeight: "bold",
          backgroundColor: "#ECE6FA",
          textColor: "#5729CE",
        };
      case "Diproses":
        return {
          fontWeight: "bold",
          backgroundColor: "#FDDACB",
          textColor: "#F84B02",
        };
      case "Diproses (File Gagal Terkirim)":
        return {
          fontWeight: "bold",
          backgroundColor: "#ECC7BE",
          textColor: "#9F2305",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3FCDB",
          textColor: "#5AC967",
        };
      case "Revisi":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE2E6",
          textColor: "#FF2B4B",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#E10F00",
          textColor: "#ffffff",
        };
      default:
        return {};
    }
  };

  // const getStatusStyleSelect = (status) => {
  //   switch (status) {
  //     case "Menunggu Konfirmasi":
  //       return { textColor: "#FFAF05" };
  //     case "Diajukan":
  //       return { textColor: "#1776F1" };
  //     case "Diproses":
  //       return { textColor: "#fa5d02" };
  //     case "Disetujui":
  //       return { textColor: "#5AC967" };
  //     case "Revisi":
  //       return { textColor: "#FF2B4B" };
  //     case "Ditolak":
  //       return { textColor: "#E10F00" };
  //     default:
  //       return {};
  //   }
  // };

  // const customStylesSelect = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: getStatusStyleSelect(state.label).backgroundColor,
  //     color: getStatusStyleSelect(state.data.label).textColor,
  //     padding: "8px",
  //     borderRadius: "4px",
  //   }),
  // };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc",
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
      // (currentPage - 1) * totalPerHalaman +
      // pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "390px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "270px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Cabang Pencairan",
      minWidth: "350px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Tanggal Pengajuan Ke Supervisor",
      minWidth: "300px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Tanggal Pengajuan Ke BPD",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPengajuanBpd,
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "300px",
      center: true,
      selector: (row) => row.tanggalPencairan,
    },
    // {
    //   name: "Diupdate Oleh",
    //   minWidth: "300px",
    //   center: true,
    //   selector: (row) => row.diupdateOleh,
    // },
    {
      name: "Status",
      minWidth: "350px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setPending(true);
    getAllPengajuanKoor(dispatch, {
      statusPengajuan: statusPengajuan,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [
    modalDetailPengajuan.modalDetailPengajuan,
    page,
    totalPerHalaman,
    statusPengajuan,
  ]);

  React.useEffect(() => {
    setPending(true);
    ambilTotalDiajukan(dispatch, {
      statusPengajuan: statusPengajuanDiajukan,
      cari: searchText,
      page: 1,
      limit: 1,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [statusPengajuanDiajukan]);

  React.useEffect(() => {
    setPending(true);
    ambilTotalPengajuan(dispatch, {
      statusPengajuan: statusPengajuanDisetujui,
      cari: searchText,
      page: 1,
      limit: 1,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [statusPengajuanDisetujui]);

  React.useEffect(() => {
    setPending(true);
    ambilDiproses(dispatch, {
      statusPengajuan: statusPengajuanDiproses,
      cari: searchText,
      page: 1,
      limit: 1,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [statusPengajuanDiproses]);

  React.useEffect(() => {
    setPending(true);
    ambilGagalTerkirim(dispatch, {
      statusPengajuan: statusPengajuanGagalTerkirim,
      cari: searchText,
      page: 1,
      limit: 1,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [statusPengajuanGagalTerkirim]);

  React.useEffect(() => {
    setPending(true);
    ambilTotalDitolak(dispatch, {
      statusPengajuan: statusPengajuanDitolak,
      cari: searchText,
      page: 1,
      limit: 1,
    })
      .then((response) => {
        // setLoading(false);
        setPending(false);
      })
      .catch((error) => {
        // setLoading(false);
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [statusPengajuanDitolak]);

  return (
    <>
      <Modal
        size="lg"
        show={modalCekStatus}
        onHide={() => setModalCekStatus(!modalCekStatus)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Cek Status Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {hasilCekStatus &&
              (hasilCekStatus.status === "101" ||
                hasilCekStatus.status === "200") ? (
                <>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Refference ID
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Nama Nasabah
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="3">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        Status Pengajuan
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.keterangan}
                      </label>
                    </Col>
                  </Row> */}
                </>
              ) : (
                <>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Refference ID
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.id_pinjaman}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Nama Nasabah
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {detailPengajuan && detailPengajuan.namaLengkap}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <li>
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Pesan
                        </label>
                      </li>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {hasilCekStatus && hasilCekStatus.message}
                      </label>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>

            <Card.Footer></Card.Footer>
          </Card>
        </Col>
      </Modal>

      {modalDetailPengajuan.pengajuanLama ? (
        <>
          {modalDetailPengajuan.modalDetailPengajuan ? (
            <>
              <Button
                onClick={() => {
                  setStatusPengajuan("Diajukan");
                }}
                style={{
                  backgroundColor: "#BAD9FD",
                  color: "#1776F1",
                  border: 0,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              >
                Diajukan
                {totalPengajuan.diajukan.length === 0 ? (
                  // <Skeleton circle={true} width={30} height={20} />
                  <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                    <Skeleton circle={true} height={"100%"} width={30} />
                  </SkeletonTheme>
                ) : (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      padding: "0.2em 0.5em",
                      marginLeft: "0.2em",
                    }}
                  >
                    {totalPengajuan.diajukan}
                  </span>
                )}
              </Button>
              <Button
                onClick={() => {
                  setStatusPengajuan("Diproses");
                }}
                style={{
                  backgroundColor: "#fcceb3",
                  color: "#fa5d02",
                  border: 0,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              >
                Diproses
                {totalPengajuan.Diproses.length === 0 ? (
                  // <Skeleton circle={true} width={30} height={20} />
                  <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                    <Skeleton circle={true} height={"100%"} width={30} />
                  </SkeletonTheme>
                ) : (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      padding: "0.2em 0.5em",
                      marginLeft: "0.2em",
                    }}
                  >
                    {totalPengajuan.Diproses}
                  </span>
                )}
              </Button>

              <Button
                onClick={() => {
                  setStatusPengajuan("Diproses (File Gagal Terkirim)");
                }}
                style={{
                  backgroundColor: "#ECC7BE",
                  color: "#9F2305",
                  border: 0,
                  fontWeight: "bold",
                  marginLeft: 15,
                  marginBottom: 10,
                }}
              >
                Diproses (File Gagal Terkirim)
                {totalPengajuan.gagalTerkirim.length === 0 ? (
                  // <Skeleton circle={true} width={30} height={20} />
                  <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                    <Skeleton circle={true} height={"100%"} width={30} />
                  </SkeletonTheme>
                ) : (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      padding: "0.2em 0.5em",
                      marginLeft: "0.2em",
                    }}
                  >
                    {totalPengajuan.gagalTerkirim}
                  </span>
                )}
              </Button>
              <Button
                onClick={() => {
                  setStatusPengajuan("Disetujui");
                }}
                style={{
                  backgroundColor: "#E3FCDB",
                  color: "#5AC967",
                  border: 0,
                  fontWeight: "bold",
                  marginLeft: 15,
                  marginBottom: 10,
                }}
              >
                Disetujui
                {totalPengajuan.disetujui.length === 0 ? (
                  // <Skeleton circle={true} width={30} height={20} />
                  <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                    <Skeleton circle={true} height={"100%"} width={30} />
                  </SkeletonTheme>
                ) : (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      padding: "0.2em 0.5em",
                      marginLeft: "0.2em",
                    }}
                  >
                    {totalPengajuan.disetujui}
                  </span>
                )}
              </Button>
              <Button
                onClick={() => {
                  setStatusPengajuan("Ditolak");
                }}
                style={{
                  backgroundColor: "#E10F00",
                  color: "#ffffff",
                  border: 0,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              >
                Ditolak
                {totalPengajuan.ditolak.length === 0 ? (
                  // <Skeleton circle={true} width={30} height={20} />
                  <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                    <Skeleton circle={true} height={"100%"} width={30} />
                  </SkeletonTheme>
                ) : (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "white",
                      color: "red",
                      padding: "0.2em 0.5em",
                      marginLeft: "0.2em",
                    }}
                  >
                    {totalPengajuan.ditolak}
                  </span>
                )}
              </Button>
              {/* {modalDetailPengajuan.modalDetailPengajuanDisetujui ? ( */}
              <Container fluid>
                <>
                  <Row>
                    <Col md="12">
                      <Card>
                        <Card.Header>
                          <Card.Title
                            as="h3"
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            List Pengajuan{" "}
                            <span
                              style={{
                                color:
                                  statusPengajuan === "Diajukan"
                                    ? "#1776F1"
                                    : statusPengajuan === "Diproses"
                                    ? "#fa5d02"
                                    : statusPengajuan ===
                                      "Diproses (File Gagal Terkirim)"
                                    ? "#9F2305"
                                    : statusPengajuan === "Disetujui"
                                    ? "#5AC967"
                                    : statusPengajuan === "Ditolak"
                                    ? "#E10F00"
                                    : "#000000",
                              }}
                            >
                              {statusPengajuan}
                            </span>
                          </Card.Title>
                          <Tab.Content>
                            <Card style={{ border: 0, marginTop: 20 }}>
                              <Row
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                <Col md={"4"}>
                                  <InputGroup>
                                    <Form.Control
                                      style={{
                                        paddingLeft: "10px",
                                      }}
                                      type="text"
                                      value={searchText}
                                      onChange={handleSearch}
                                      onKeyDown={handleKeyDown}
                                      placeholder="Reffrence ID/Nasabah"
                                    />
                                    <InputGroup.Append>
                                      <InputGroup.Text
                                        style={{ cursor: "pointer" }}
                                        onClick={handleClearSearch}
                                      >
                                        <i class="fas fa-times"></i>
                                      </InputGroup.Text>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </Col>

                                {/* <Col md={"3"}>
                                <div>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Status"
                                    value={filterStatus}
                                    onChange={(selectedOption) => {
                                      setFilterStatus(selectedOption);
                                      setPilihOpsi(selectedOption.value);
                                    }}
                                    defaultValue={{
                                      label: "All",
                                      value: "",
                                    }}
                                    options={[...listStatus]}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Pilih Status"
                                  />
                                </div>
                              </Col> */}
                                <Row>
                                  <Col>
                                    <Button
                                      // variant="info"
                                      style={{
                                        fontSize: 16,
                                        backgroundColor: "#FF474C",
                                        border: 0,
                                        paddingTop: 10,
                                        paddingBottom: 7,
                                      }}
                                      onClick={handleFilterChange}
                                    >
                                      <i class="fas fa-search"></i>
                                    </Button>
                                  </Col>
                                  {/* <Col> */}
                                  {auth.role === "super admin" ||
                                  auth.role === "admin" ? (
                                    <>
                                      <Button
                                        className="mr-1"
                                        // variant="primary"
                                        style={{
                                          marginBottom: 10,
                                          fontSize: 16,
                                          backgroundColor: "#BBC9D3",
                                          color: "#425A6B",
                                          border: 0,
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          textTransform: "capitalize",
                                          fontWeight: "bold",
                                        }}
                                        onClick={hidePengajuanLama}
                                      >
                                        pengajuan lama
                                      </Button>
                                    </>
                                  ) : null}
                                </Row>
                              </Row>
                            </Card>
                          </Tab.Content>
                        </Card.Header>
                        <Card.Body>
                          {pending ? (
                            <div
                              className="loader-container"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <div style={{ marginBottom: 20 }}>
                                <Hourglass
                                  visible={true}
                                  height="50"
                                  width="50"
                                  ariaLabel="hourglass-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  colors={["#306cce", "#72a1ed"]}
                                />
                                {/* <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 19,
                          }}
                        >
                          Loading ....
                        </p> */}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {listPengajuan.length > 0 ? (
                                <DataTable
                                  columns={colPengajuan}
                                  data={listPengajuan}
                                  customStyles={customStyles}
                                  paginationPerPage={totalPerHalaman}
                                  progressPending={pending}
                                />
                              ) : (
                                <div style={{ textAlign: "center" }}>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    Maaf, belum ada pengajuan{" "}
                                    <span
                                      style={{
                                        color:
                                          statusPengajuan === "Diajukan"
                                            ? "#1776F1"
                                            : statusPengajuan === "Diproses"
                                            ? "#fa5d02"
                                            : statusPengajuan ===
                                              "Diproses (File Gagal Terkirim)"
                                            ? "#9F2305"
                                            : statusPengajuan === "Disetujui"
                                            ? "#5AC967"
                                            : statusPengajuan === "Ditolak"
                                            ? "#E10F00"
                                            : "#000000",
                                      }}
                                    >
                                      {statusPengajuan}
                                    </span>{" "}
                                    saat ini.
                                  </p>
                                  <img
                                    src={
                                      require("assets/img/sorry.jpg").default
                                    }
                                    alt="Sorry"
                                    style={{
                                      maxWidth: "20%",
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </div>
                              )}
                              <div style={{ marginTop: 35 }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Col>
                                    <Row style={{ alignItems: "center" }}>
                                      <div
                                        style={{
                                          paddingRight: 0,
                                          marginLeft: 20,
                                          marginRight: 10,
                                        }}
                                      >
                                        <label style={{ fontWeight: "bold" }}>
                                          Rows per page
                                        </label>
                                      </div>
                                      <Col sm="3" style={{ paddingLeft: 0 }}>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          name="Pilih Client"
                                          value={options.find(
                                            (option) =>
                                              option.value === totalPerHalaman
                                          )}
                                          onChange={handlePageSizeChange}
                                          options={options}
                                          placeholder="Rows per page"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col>
                                    <ReactPaginate
                                      forcePage={page}
                                      containerClassName="paginations justify-content-end"
                                      previousClassName="pages-items-containers"
                                      previousLinkClassName="pages-links-labels"
                                      nextClassName="pages-items-containers"
                                      nextLinkClassName="pages-links-labels"
                                      breakClassName="pages-items-containers"
                                      pageCount={
                                        pengajuan.listPengajuan.totalPages
                                      }
                                      pageRangeDisplayed={3}
                                      marginPagesDisplayed={1}
                                      pageLinkClassName="pages-links-labels"
                                      pageClassName="pages-items-containers"
                                      activeClassName="activees"
                                      activeLinkClassName="activess"
                                      nextLabel=" > "
                                      previousLabel=" < "
                                      initialPage={page}
                                      disableInitialCallback={true}
                                      onPageChange={(val) => {
                                        setPage(val.selected);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </>
              </Container>
            </>
          ) : (
            <>
              <DetailPengajuan />
            </>
          )}
        </>
      ) : (
        <PengajuanLama />
      )}
    </>
  );
}

export default Pengajuan;
