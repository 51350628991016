import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
  Tab,
  Row,
} from "react-bootstrap";
import { loginKoordinator } from "stores/authKoordinator/function";

function LoginPageClient() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [id, setId] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  const handlePrivacyLinkClick = () => {
    window.open("/privacy", "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form action="" className="form" method="">
                <Card
                  className={"card-login " + cardClasses}
                  style={{ backgroundColor: "#CEE4FB" }}
                >
                  <Card.Header style={{ backgroundColor: "#CEE4FB" }}>
                    <h3
                      className="header text-center"
                      style={{ fontWeight: "bold", color: "#000000" }}
                    >
                      Login Klien
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label style={{ color: "#000000" }}>
                          <b>Username</b>
                        </label>
                        <Form.Control
                          value={id}
                          onChange={(e) => {
                            setId(e.target.value);
                          }}
                          placeholder="Enter Username"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label style={{ color: "#000000" }}>
                          <b>Password</b>
                        </label>
                        <Form.Control
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Password"
                          type="password"
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button
                      onClick={() => {
                        loginKoordinator(dispatch, { id, password }, history);
                      }}
                      className="btn-wd"
                      type="submit"
                      // variant="warning"
                      style={{
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        backgroundColor: "#157DEC",
                        border: 0,
                      }}
                    >
                      Login
                    </Button>
                  </Card.Footer>
                  <a
                    href="/auth/login-page"
                    // target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#FF003F",
                      fontSize: 16,
                      textDecoration: "none",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Kembali ke login BAP
                  </a>
                </Card>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href="/auth/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "white",
                      fontSize: 14,
                      textDecoration: "none",
                      marginLeft: 25,
                    }}
                  >
                    Privacy Policy
                  </a>
                  <label
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      color: "white",
                    }}
                  >
                    |
                  </label>
                  <a
                    href="/auth/termsandconditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "white",
                      fontSize: 14,
                      textDecoration: "none",
                    }}
                  >
                    Terms & Conditions
                  </a>
                </div>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-1.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPageClient;
