const initialState = {
  nama: "",
  nopeg: "",
  role: "",
  idClient: "",
  idCabang: "",
  idUser: "",
  kodeUser: "",
  fullname: "",
  email: "",
  koordinator: "",
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_IDENTITY":
      return {
        ...state,
        nama: data.nama,
        nopeg: data.nopeg,
        role: data.role,
        idClient: data.idClient,
        idCabang: data.idCabang,
        idUser: data.idUser,
        kodeUser: data.kodeUser,
      };
    case "SET_UBAH_PASSWORD":
      return {
        ...state,
        nopeg: data.nopeg,
        role: data.role,
      };
    case "SET_IDENTITY_KOORDINATOR":
      return {
        ...state,
        fullname: data.fullname,
        email: data.email,
        koordinator: data.koordinator,
      };
    default:
      return state;
  }
};

export default authReducer;
