import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { getAllCabangKoor } from "stores";
import Select from "react-select";
import { editCabangPencairan } from "stores";

function Step4() {
  const dispatch = useDispatch();
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const storeCabang = useSelector((state) => state.cabangReducer);
  const valDetail = pengajuan.detailPengajuan;
  // console.log(valDetail, "LLLLLLLLLL");
  const [text, setText] = React.useState("");
  const [listCabang, setListCabang] = React.useState([]);
  // const [pilihCabang, setPilihCabang] = React.useState(null);
  // const [idCabang, setIdCabang] = React.useState(null);
  // const [singkatan, setSingkatan] = React.useState(null);
  const [pilihCabang, setPilihCabang] = React.useState({
    label: `${valDetail.lokasiPencairan?.namaCabang} - ${valDetail.lokasiPencairan.singkatan}`,
    value: valDetail.lokasiPencairan?.idCabangPencairan,
    singkatan: valDetail.lokasiPencairan?.singkatan,
  });
  const [idCabang, setIdCabang] = React.useState(
    valDetail.lokasiPencairan?.idCabangPencairan
  );
  const [singkatan, setSingkatan] = React.useState(
    valDetail.lokasiPencairan?.singkatan
  );

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const submitPilihCabang = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      editCabangPencairan({
        idCabang: idCabang,
        singkatan: singkatan,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setIdCabang(null);
          setPilihCabang(null);
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeCabang.listCabangKoor &&
      storeCabang.listCabangKoor.map((val) => {
        tmp.push({
          value: val.cabang_id,
          label: `${val.namaCabang} - ${val.singkatan}`,
          singkatan: val.singkatan,
        });
      });

    setListCabang(tmp);
  }, [storeCabang.listCabangKoor]);

  React.useEffect(() => {
    getAllCabangKoor(dispatch);
  }, []);

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          infomrasi pinjaman
          <hr></hr>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "650px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tujuanPenggunaan === "3"
                      ? "Konsumtif"
                      : "Produktif"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  Jenis Kredit
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.jenisKredit === "01"
                      ? "Multiguna Utama"
                      : valDetail.stepEmpat.jenisKredit === "02"
                      ? "Multiguna Pensiunan"
                      : valDetail.stepEmpat.jenisKredit === "03"
                      ? "Multiguna Kretap"
                      : valDetail.stepEmpat.jenisKredit === "04"
                      ? "Multiguna Terusan"
                      : "Multiguna Plus"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tenor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tenor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal permohonan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Rp ${valDetail.stepEmpat.nominalPermohonan.toLocaleString(
                      "id-ID"
                    )}`}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nip
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.nip}
                  </Label>
                </li>
              </div> */}
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  suku bunga
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.sukuBunga}%
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  detail tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.detailTujuanPenggunaan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  status pinjaman
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.statusPinjaman === 1
                      ? "New"
                      : valDetail.stepEmpat.statusPinjaman === 2
                      ? "Top Up"
                      : valDetail.stepEmpat.statusPinjaman === 3
                      ? "Kompensasi"
                      : valDetail.stepEmpat.statusPinjaman === 4
                      ? "Take Over"
                      : "Pelunasan Di Pengajuan"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  cabang pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {/* {valDetail.stepEmpat.idCabangPencairan ===
                    valDetail.lokasiPencairan.namaCabang
                      ? valDetail.lokasiPencairan.namaCabang
                      : valDetail.lokasiPencairan.namaCabang} */}
                    {valDetail.lokasiPencairan.namaCabang} -{" "}
                    {valDetail.lokasiPencairan.singkatan}
                  </Label>
                </li>
              </div>
              {/* <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  ID Pengajuan
                </Label>
                <Input
                  type="text"
                  placeholder="Input ID Pengajuan"
                  value={text}
                  onChange={handleChange}
                  style={{
                    fontSize: 14,
                    marginBottom: 15,
                  }}
                />
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  Edit Cabang Pencairan
                </Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="Pilih Cabang Pencairan"
                  value={pilihCabang}
                  onChange={(value) => {
                    setPilihCabang(value);
                    setIdCabang(value.value);
                    setSingkatan(value.singkatan);
                  }}
                  options={listCabang}
                  placeholder="Pilih Cabang"
                />
                <Button
                  style={{ marginTop: 10 }}
                  className="btn-wd mr-0"
                  variant="primary"
                  onClick={submitPilihCabang}
                >
                  Submit
                </Button>
              </div> */}
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step4;
