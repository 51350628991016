import { baseAxios } from "../index";

export async function getAllPengajuanKoor(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function ambilTotalDitolak(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_AMBIL_DITOLAK",
      data: response.data.totalDocs,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function ambilTotalDiajukan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_AMBIL_DIAJUKAN",
      data: response.data.totalDocs,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function ambilTotalPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_AMBIL_DISETUJUI",
      data: response.data.totalDocs,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function ambilDiproses(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_AMBIL_DIPROSES",
      data: response.data.totalDocs,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function ambilGagalTerkirim(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanKoor?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_AMBIL_GAGALTERKIRIM",
      data: response.data.totalDocs,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanBPD(data) {
  try {
    const response = await baseAxios.post("webApiBpd/pengajuan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function revisiPengajuanDariKoor(data) {
  try {
    const response = await baseAxios.post("webPengajuan/ubahRevisi", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
